<template>
	<div>
        <skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

		<div v-else>
			<div v-if="checkTask != '' || formType == 'edit' || formType == 'detail' || formType == 'sapSyncTaskEdit'">
                <McrHeader @emitHeader="applyVarMcrType"></McrHeader>
				<br>

				<!-- KONDISI UNTUK MCR FORM REQUEST -->

				<div v-if="formType == 'edit'">
					<h3>{{ tt("mcr_item") }}</h3>
					<McrFormA1 v-if="mcrType == 'A1'" @saveA1="callStatusProcess"></McrFormA1>
					<McrFormA2 v-if="mcrType == 'A2'" @processA2="callStatusProcess" ref="FormAChange"></McrFormA2>
					<McrFormA3 v-if="mcrType == 'A3'" @saveA3="callStatusProcess"></McrFormA3>
					<McrFormA4 v-if="mcrType == 'A4'" @saveA4="callStatusProcess"></McrFormA4>
					<McrFormB1 v-if="mcrType == 'B1'" @saveB1="callStatusProcess"></McrFormB1>
					<McrFormB v-if="mcrType == 'B2' || mcrType == 'B4'" @saveB="callStatusProcess"></McrFormB>
					<McrFormB3 v-if="mcrType == 'B3'" @saveB3="callStatusProcess" ref="FormBChange"></McrFormB3>
					<McrFormB5 v-if="mcrType == 'B5'" @saveB5="callStatusProcess"></McrFormB5>
					<McrFormAbcEvaluation v-if="mcrType == 'C1'"></McrFormAbcEvaluation>
					<McrFormA1 v-if="mcrType == 'A1S'" @saveA1="callStatusProcess" @showShpA1s="formShpModal" @validateEqpMcrWithShpForm="eqpCodeAndCodeFromShpForm" ref="FormA1"></McrFormA1>
					<McrFormB v-if="mcrType == 'B2S'" @saveB="callStatusProcess" @showShpB="formShpModal" @validateEqpMcrWithShpForm="eqpCodeAndCodeFromShpForm" ref="FormB"></McrFormB>
				</div>

				<!-- KONDISI UNTUK MCR FORM APPROVAL -->

				<div v-if="formType == 'approval' || formType == 'detail' || formType == 'sapSyncTaskEdit'">
					<McrFormShp v-if="level == 'SHP' && mcrType == 'A2'" ref="shp" @saveShp="callStatusProcess"></McrFormShp>
					<tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow" v-if="['A1','A1S','A2','A3'].includes(mcrType)">
						<tab-pane v-if="mcrType == 'A1' || mcrType == 'A1S' || ((level == 'Specification' || level == 'RopMinMax') && mcrType == 'A2') || mcrType == 'A3'">
							<span slot="title">User Request</span>
							<p class="card-body description">
								<McrFormA1 v-if="mcrType == 'A1' || mcrType == 'A1S'"  @saveA1="callStatusProcess" @showShpA1s="formShpModal" @validateEqpMcrWithShpForm="eqpCodeAndCodeFromShpForm" ref="FormA1"></McrFormA1>
								<McrFormA2 v-if="mcrType == 'A2'" @processA2="callStatusProcess" ref="FormAChange"></McrFormA2>
								<McrFormA3 v-if="mcrType == 'A3'" @saveA3="callStatusProcess"></McrFormA3>
							</p>
						</tab-pane>

						<tab-pane v-if="(mcrType == 'A1' || mcrType == 'A1S' || ((level == 'Specification' || level == 'RopMinMax') && mcrType == 'A2') || mcrType == 'A3') && (authUserRole['Cataloguer'] || authUserRole['Senior Cataloguer'] || authUserRole['Manager Cataloguer'] || authUserRole['Master Data PI'] || authUserRole['Administrator'])">
							<span slot="title">Cataloguing Process</span>
							<p class="card-body description">
								<McrFormB ref="FormB" @saveB="callStatusProcess" @saveA="callStatusProcess" @processA2="callStatusProcess" @saveA3="callStatusProcess"  @showShpB="formShpModal" @validateEqpMcrWithShpForm="eqpCodeAndCodeFromShpForm"></McrFormB>
							</p>
						</tab-pane>
					</tabs>

					<div v-if="['A4','B1','B2','B2S','B3','B4','B5','C1'].includes(mcrType)">
						<McrFormA4 v-if="mcrType == 'A4'" @saveA4="callStatusProcess"></McrFormA4>
						<McrFormB1 v-if="mcrType == 'B1'" @saveB1="callStatusProcess"></McrFormB1>
						<McrFormB  v-if="mcrType == 'B2' || mcrType == 'B4'" @saveB="callStatusProcess"></McrFormB>
						<McrFormB3 v-if="mcrType == 'B3'" @saveB3="callStatusProcess" ref="FormBChange"></McrFormB3>
						<McrFormB5 v-if="mcrType == 'B5'" @saveB5="callStatusProcess"></McrFormB5>
						<McrFormAbcEvaluation v-if="mcrType == 'C1'"></McrFormAbcEvaluation>
						<McrFormB v-if="mcrType == 'B2S'" @saveB="callStatusProcess" @showShpB="formShpModal" ref="FormB" @validateEqpMcrWithShpForm="eqpCodeAndCodeFromShpForm"></McrFormB>
					</div>
				</div>

				<McrFooter ref="statusProcess"></McrFooter>

				<!-- MODAL SHP -->
				<el-dialog
					:title="tt('stock_holding_policy')"
					:visible.sync="formShp"
					width="60%"
					:before-close="closeShpForm"
					:close-on-click-modal="false"
					append-to-body>
					<McrFormShp ref="shp" @saveShp="callStatusProcess" @closeShp="closeShpForm" @containedValuesShp="actContainedValuesShp"></McrFormShp>
				</el-dialog>

					<!-- <modal :show.sync="formShp" size="lg" @before-close="beforeClose">
						<template slot="header">
							<h5 class="modal-title">{{ tt('stock_holding_policy')}}</h5>
						</template>
						<div>
							<McrFormShp ref="shp" @saveShp="callStatusProcess" @closeShp="closeShpForm" @containedValuesShp="actContainedValuesShp"></McrFormShp>
						</div>
						<template slot="footer">
							<base-button type="secondary" @click="formShpModal">{{ tt('close') }}</base-button>
						</template>
					</modal> -->
			</div>
			<expiredpage v-else/>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import myTaskDashboard from '@/services/dashboard/myTaskDashboard.service';

	// MCR FORM
	import McrHeader from "./McrHeader.vue";
	import McrFooter from "./McrFooter.vue";
	import McrFormA1 from "./McrFormA1.vue";
	import McrFormA2 from "./McrFormA2.vue";
	import McrFormA3 from "./McrFormA3.vue";
	import McrFormA4 from "./McrFormA4.vue";
	import McrFormB1 from "./McrFormB1.vue";
	import McrFormB from "./McrFormB.vue";
	import McrFormB3 from "./McrFormB3.vue";
	import McrFormB5 from "./McrFormB5.vue";
	import McrFormAbcEvaluation from "./McrFormAbcEvaluation.vue";
	import McrFormShp from "./McrFormShp.vue";

	export default {
		components: {
	      McrHeader,
	      McrFooter,
		  McrFormA1,
		  McrFormA2,
		  McrFormA3,
		  McrFormA4,
		  McrFormB1,
		  McrFormB,
		  McrFormB3,
		  McrFormB5,
		  McrFormAbcEvaluation,
		  McrFormShp,
	    },
		props:['saveA1', 'processA2','saveA3','saveA4','saveB','saveB1','saveB3','saveB5', 'saveShp', 'showShpA1s','showShpB','closeShp', 'emitHeader', 'validateEqpMcrWithShpForm', 'containedValuesShp'],
	    data() {
	    	return {
                requestCalls: 0,
                checkTask: '',
				mcrType: this.$route.params.mcr_type,
	    		mcr_code: this.$route.params.mcr_code,
	    		mcrItemCode: this.$route.params.mcr_item_code,
				formType: this.$route.params.type,
				level: '',
				formShp:false,
				valuesShpComeFromOutside: {},
	    	}
	    },
	    computed: {
            ...mapState({
                authUser :state => state.auth.user,
                authUserPermission :state => state.auth.userPermission,
                authUserRole :state => state.auth.userRole
            }),
        },
	    mounted() {
			if (this.formType == 'approval') {
				this.checkTaskTicket();
			}
			this.clientPlantLevel()
		},
		methods: {
		    checkTaskTicket() { // untuk cek apakah user login punya task ticket yang bersangkutan
		    	this.requestCalls++;

                let context = this;
                Api(context, myTaskDashboard.check_task_ticket(this.mcr_code)).onSuccess(function(response) {
                    context.checkTask = response.data;
                }).onError(function(error) {
                    context.checkTask = [];
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
            },
			clientPlantLevel() {
				let context = this;

		      	Api(context, draftList.getDetailMcrItem(this.mcrItemCode))
		        .onSuccess(function(response) {
		            context.level = response.data.data[0].client_plant_level;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
					context.level = ''
				  }
		        }).onFinish(() => {
					context.requestCalls--;
				})
		        .call();
			},
			callStatusProcess(event){
				this.$refs.statusProcess.get();
				// if(event === 'shp'){
					// if(this.$refs.FormA1){
					// 	this.$refs.FormA1.getDetailMcrItem()
					// }
					// if(this.$refs.FormB){
					// 	this.$refs.FormB.getDetailMcrItem()
					// }
				// }
			},
			formShpModal(eqp, valuesSHP){
				if(eqp){
					this.formShp = !this.formShp
					// this.$refs.shp.input.shp_equipment = eqp
					// this.$refs.shp.input.shp_uom = uom_code
					// this.$refs.shp.input.shp_uom_mirroring = uom_code
					// this.$refs.shp.input.shp_owner_estimate = price_reference
					// this.$refs.shp.input.code = code
					// this.$refs.shp.input.id = id
					// this.$refs.equipments = []
					// this.$refs.shp.getDetailMcrItem()

					this.$set(this, 'valuesShpComeFromOutside', {
						input: valuesSHP.input,
						equipments: valuesSHP.equipments,
						materialNumbers: valuesSHP.materialNumbers,
						manufacturePartNumber: valuesSHP.manufacturePartNumber
					});

					 this.$nextTick(() => {
						if (this.$refs.shp) {

							this.$set(this.$refs.shp.input, 'id', valuesSHP.input.id)
							this.$set(this.$refs.shp.input, 'code', valuesSHP.input.code)
							this.$set(this.$refs.shp, 'equipments', valuesSHP.equipments)
							this.$set(this.$refs.shp.input, 'shp_equipment', valuesSHP.input.shp_equipment || valuesSHP.equipments[0].equipment_code)
							this.$set(this.$refs.shp.input, 'shp_failure_mode', valuesSHP.input.shp_failure_mode)
							this.$set(this.$refs.shp.input, 'shp_qty', valuesSHP.input.shp_qty)
							this.$set(this.$refs.shp.input, 'shp_uom', valuesSHP.input.shp_uom)
							this.$set(this.$refs.shp.input, 'shp_uom_mirroring', valuesSHP.input.shp_uom)
							this.$set(this.$refs.shp.input.shp_mce_strategy, 'mce_strategy', valuesSHP.input.shp_mce_strategy.mce_strategy)
							this.$set(this.$refs.shp.input.shp_mce_strategy, 'mce_interval', valuesSHP.input.shp_mce_strategy.mce_interval)
							this.$set(this.$refs.shp.input.shp_mce_strategy, 'mce_net_pf', valuesSHP.input.shp_mce_strategy.mce_net_pf)
							this.$set(this.$refs.shp.input.shp_mce_strategy, 'mce_tindakan', valuesSHP.input.shp_mce_strategy.mce_tindakan)
							this.$set(this.$refs.shp.input, 'shp_effect_spare_part_not_available', valuesSHP.input.shp_effect_spare_part_not_available)
							this.$set(this.$refs.shp.input, 'shp_demand_rate', valuesSHP.input.shp_demand_rate)
							this.$set(this.$refs.shp.input, 'shp_demand_rate_unit', valuesSHP.input.shp_demand_rate_unit)
							this.$set(this.$refs.shp.input, 'shp_action_to_handle', valuesSHP.input.shp_action_to_handle)
							this.$set(this.$refs.shp.input, 'shp_lead_time_action_to_handle', valuesSHP.input.shp_lead_time_action_to_handle)
							this.$set(this.$refs.shp.input, 'shp_consequences_criticality', valuesSHP.input.shp_consequences_criticality)
							this.$set(this.$refs.shp.input, 'shp_owner_estimate', valuesSHP.input.shp_owner_estimate)
							this.$set(this.$refs.shp.input, 'shp_estimated_loss_idr', valuesSHP.input.shp_estimated_loss_idr)
							this.$set(this.$refs.shp.input, 'shp_estimated_loss_day', valuesSHP.input.shp_estimated_loss_day)
							this.$set(this.$refs.shp.input, 'shp_total_estimated_loss', valuesSHP.input.shp_total_estimated_loss)
							this.$set(this.$refs.shp.input, 'shp_unit_price', valuesSHP.input.shp_unit_price)
							this.$set(this.$refs.shp.input, 'shp_quantity_on_hand', valuesSHP.input.shp_quantity_on_hand)
							this.$set(this.$refs.shp.input, 'shp_value_unrestricted', valuesSHP.input.shp_value_unrestricted)
							this.$set(this.$refs.shp.input, 'shp_estimated_lead_time', valuesSHP.input.shp_estimated_lead_time)
							this.$set(this.$refs.shp.input, 'shp_category_lead_time', valuesSHP.input.shp_category_lead_time)
							this.$set(this.$refs.shp.input, 'shp_abc_indicator', valuesSHP.input.shp_abc_indicator)
							this.$set(this.$refs.shp.input, 'shp_original_stock_strategy', valuesSHP.input.shp_original_stock_strategy)
							this.$set(this.$refs.shp.input, 'shp_stock_strategy', valuesSHP.input.shp_stock_strategy)
							this.$set(this.$refs.shp.input, 'shp_change_mrp_type', valuesSHP.input.shp_change_mrp_type)
							this.$set(this.$refs.shp.input, 'shp_original_stock_level_type_calculation', valuesSHP.input.shp_original_stock_level_type_calculation)
							this.$set(this.$refs.shp.input, 'shp_stock_level_type_calculation', valuesSHP.input.shp_stock_level_type_calculation)
							this.$set(this.$refs.shp.input, 'shp_original_service_level', valuesSHP.input.shp_original_service_level)
							this.$set(this.$refs.shp.input, 'shp_service_level', valuesSHP.input.shp_service_level)
							this.$set(this.$refs.shp.input, 'shp_original_stock_level', valuesSHP.input.shp_original_stock_level)
							this.$set(this.$refs.shp.input, 'shp_stock_level', valuesSHP.input.shp_stock_level)
							this.$set(this.$refs.shp.input, 'shp_original_min', valuesSHP.input.shp_original_min)
							this.$set(this.$refs.shp.input, 'shp_min', valuesSHP.input.shp_min)
							this.$set(this.$refs.shp.input, 'shp_original_max', valuesSHP.input.shp_original_max)
							this.$set(this.$refs.shp.input, 'shp_max', valuesSHP.input.shp_max)
							this.$set(this.$refs.shp.input, 'shp_date_during_analysis', valuesSHP.input.shp_date_during_analysis)
							this.$set(this.$refs.shp.input, 'shp_resume_justification_stock_strategy', valuesSHP.input.shp_resume_justification_stock_strategy)

							if(valuesSHP.materialNumbers.length != 0 && (this.mcrType == 'A2' || this.mcrType == 'B3')) {
								this.$set(this.$refs.shp, 'materialNumbers', valuesSHP.materialNumbers)
							}

							if ( valuesSHP.manufacturePartNumber.manufacture_code != 0 && (this.mcrType == 'A2' || this.mcrType == 'B3')) {
								this.$set(this.$refs.shp.manufacturePartNumber, 'manufacture_code', valuesSHP.manufacturePartNumber.manufacture_code)
								this.$set(this.$refs.shp.manufacturePartNumber, 'mpn', valuesSHP.manufacturePartNumber.mpn)
								this.$set(this.$refs.shp.manufacturePartNumber, 'source_type', valuesSHP.manufacturePartNumber.source_type)
								this.$set(this.$refs.shp.manufacturePartNumber, 'position', valuesSHP.manufacturePartNumber.position)
								this.$set(this.$refs.shp.manufacturePartNumber, 'manufacture_type', valuesSHP.manufacturePartNumber.manufacture_type)
								this.$set(this.$refs.shp.manufacturePartNumber, 'manufacture_note', valuesSHP.manufacturePartNumber.manufacture_note)
							}

						} else {
							this.$notify({
								message: "shp ref is not available",
								type: "danger",
								verticalAlign: "bottom",
								horizontalAlign: "left"
							})
							return
						}
					});
				}else{
					this.$notify({
						message: this.tt("please_complete_data", { title: this.tt("equipment") }),
						type: "danger",
						verticalAlign: "bottom",
						horizontalAlign: "left"
					});
					return;
				}
			},
			closeShpForm(){
				this.formShp = false;
				this.emptyValInMcrFormShp(this.valuesShpComeFromOutside)               
			},
			actContainedValuesShp(containedValues, keyFormShp){
				switch (this.mcrType) {
                    case 'A1S':
						this.formShp = !this.formShp
						if (!!containedValues.input.shp_estimated_lead_time) {
							this.updateValuesShpInMainUserOrCatForm('FormB', containedValues, keyFormShp)
						} else {
							this.updateValuesShpInMainUserOrCatForm('FormA1', containedValues, keyFormShp)
						}
                        break;
                    case 'B2S':          
						this.updateValuesShpInMainUserOrCatForm('FormB', containedValues, keyFormShp)
                        break;
					case 'A2':
						this.$refs.FormAChange
						break;
					case 'B3':
						this.$refs.FormAChange
						break;
                    default:
                        break;
                }
			},
			updateValuesShpInMainUserOrCatForm(formType, containedValues, keyFormShp){
				this.$delete(this.$refs[formType].valuesOfShpForm, keyFormShp)
				this.$set(this.$refs[formType].valuesOfShpForm, keyFormShp, containedValues)
			},
			applyVarMcrType(typeMcr){
				if(this.$refs.FormA1) {
					this.$refs.FormA1.varMcrType = typeMcr
					this.$refs.FormA1.getDetailMcrItem()
				}
				if(this.$refs.FormB){
					this.$refs.FormB.varMcrType = typeMcr
					this.$refs.FormB.getDetailMcrItem()
				}

				// //TypeMcr = consumable || suku_cadang_penyangga || not_applicable
				// if (typeMcr && (this.authUserRole['User Pemeliharaan'] || this.authUserRole['Staff Reliability'] || this.authUserRole['Cataloguer'] )) {					
				// 	//munculin notification
				// 	if (typeMcr === 'consumable') {
				// 		this.$notify({
				// 			message: this.tt('consumable_notification'),                  
				// 			type: 'warning',
				// 			verticalAlign: "bottom",
				// 			horizontalAlign: "left"
				// 		});
				// 	} else {
				// 		this.$notify({
				// 			message: this.tt('suku_cadang_penyangga_and_not_applicable_notification'),                  
				// 			type: 'warning',
				// 			verticalAlign: "bottom",
				// 			horizontalAlign: "left"
				// 		});
				// 	}
				// }
			},
			eqpCodeAndCodeFromShpForm(storedEqpCode, codeFormShp, tabShp) {
				if(this.$refs.statusProcess) {
					if (tabShp === 'reliability') {
						this.$refs.statusProcess.tabReliability = tabShp
						this.$refs.statusProcess.eqpCodeStoredForReliability = storedEqpCode
						this.$refs.statusProcess.codeFormShpForReliability = codeFormShp
					} else if (tabShp === 'cataloguer') {
						this.$refs.statusProcess.tabCataloguer = tabShp
						this.$refs.statusProcess.eqpCodeStoredForCataloguer = storedEqpCode
						this.$refs.statusProcess.codeFormShpForCataloguer = codeFormShp
					}
				}
			},
			emptyValInMcrFormShp(values) {
				this.$refs.shp.input = { 
					id: '',
					code: "",
					material_number: '',
					shp_failure_mode: [],
					shp_qty: '',
					shp_uom: values.input.shp_uom,
					shp_mce_strategy: {
						mce_strategy: [],
						mce_interval: [],
						mce_net_pf: [],
						mce_tindakan: []
					},
					shp_effect_spare_part_not_available: '',
					shp_demand_rate: '',
					shp_demand_rate_unit: '',
					shp_action_to_handle: '',
					shp_lead_time_action_to_handle: '',
					shp_consequences_criticality: '',
					shp_owner_estimate: values.input.shp_owner_estimate,
					shp_estimated_loss_idr: '',
					shp_estimated_loss_day: '',
					shp_total_estimated_loss: '',
					shp_estimated_lead_time: '',
					shp_category_lead_time: '',
					shp_abc_indicator: '',
					shp_unit_price: '',
					shp_quantity_on_hand: '0',
					shp_value_unrestricted: '',
					shp_change_mrp_type: '',
					shp_resume_justification_stock_strategy: '',
					shp_equipment: '',
					shp_original_stock_strategy: '',
					shp_stock_strategy: '',
					shp_original_stock_level_type_calculation: '',
					shp_stock_level_type_calculation: '',
					shp_original_service_level: '',
					shp_service_level: '',
					shp_original_stock_level: '',
					shp_stock_level: '',
					shp_original_min: '',
					shp_min: '',
					shp_original_max: '',
					shp_max: '',
					shp_date_during_analysis: '',
				}
				this.$refs.shp.equipments 	   = []
				this.$refs.shp.materialNumbers = []
				this.$refs.shp.recordChangeLog = {}
				this.$refs.shp.oldValue 	   = {}
				this.$refs.shp.newValue		   = {}
				this.$refs.shp.manufacturePartNumber = {
					manufacture_code: [],
					manufacture_code_view: [],
					mpn: [],
					source_type: [],
					position: [],
					manufacture_type: [],
					manufacture_note: []
				}
			}
		}
	};	
</script>

// NOTES
// USER
// A1  = CREATE MATERIAL
// A1S = CREATE MATERIAL WITH SHP
// A2  = CHANGE MATERIAL
// A3  = EXTEND MATERIAL
// A4  = DELETE MATERIAL

// CATALOGUER
// B1  = CREATE & EDIT MASTER
// B2  = CREATE MATERIAL
// B2S = CREATE MATERIAL WITH SHP
// B3  = CHANGE MATERIAL
// B4  = EXTEND MATERIAL
// B5  = DELETE MATERIAL	