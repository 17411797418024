<template>
    <div>
        <label class="form-control-label">Level <span class="text-danger">*</span></label>
        <base-input :name="tt('client_level')" rules="required">
            <el-select v-model="client_plant_level" :placeholder="tt('choose_client_level_or_plant_level')" :disabled="formType == 'detail' || formType == 'approval'" @change="changeLevel">
                <el-option value="Specification" label="Specification" key="Specification"></el-option>
                <el-option value="RopMinMax" label="ROP & Min-Max" key="RopMinMax"></el-option>
                <el-option value="BoM" label="Bill of Material" key="BoM" v-if="authUserRole['Staff Reliability']"></el-option>
                <el-option value="SHP" label="Stock Holding Policy" key="Stock Holding Policy"></el-option>
            </el-select>
        </base-input>

        <McrFormA2a v-if="client_plant_level == 'Specification' || client_plant_level == 'BoM'" @saveA2a="processA2" ref="formA2a" :test="client_plant_level"></McrFormA2a>
        <McrFormA2b v-if="client_plant_level == 'RopMinMax'" @saveA2b="processA2"></McrFormA2b>
        <McrFormShp v-if="client_plant_level == 'SHP'" @saveShp="processA2"></McrFormShp>
        <!-- <McrFormShp ref="shp" v-if="client_plant_level == 'SHP'" @saveShp="processA2" @containedValuesShp="actContainedValuesShp"></McrFormShp> -->
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import McrFormA2a from "./McrFormA2a.vue";
    import McrFormA2b from "./McrFormA2b.vue";
    import McrFormShp from "./McrFormShp.vue";

    export default {       
        components: {
            McrFormA2a,
            McrFormA2b,
            McrFormShp,
        } ,
        props:['saveA2a', 'saveA2b', 'saveShp'],
        data() {
            return {
                client_plant_level: '',
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
                valuesShpComeFromOutside: {}
            }
        },
        computed: {
            ...mapState({
                authUserRole :state => state.auth.userRole
            }),
        },
        mounted() {
            this.getDetailMcrItem()
        },
        methods: {
            getDetailMcrItem() {
                let context = this; 
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.client_plant_level = response.data.data[0].client_plant_level;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.client_plant_level = '';
                    }
                }).call()
            },
            processA2(){
                this.$emit('processA2');
            },
            changeLevel(event) {
                this.$refs.formA2a.level = event
            }
        }
    };
</script>